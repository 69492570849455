import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from '@/store'
Vue.use(VueI18n)

import en from '@/locale/en.json'
import ja from '@/locale/ja.json'
import tw from '@/locale/tw.json'

let locale = store.getters.getLocale
if (locale === null) {
  const lang =
    (window.navigator.languages && window.navigator.languages[0]) ||
    window.navigator.language ||
    window.navigator.userLanguage ||
    window.navigator.browserLanguage
  locale = lang.match(/^ja/) ? 'ja' : lang.match(/^zh/) ? 'tw' : 'en'
  store.commit('setLocale', locale)
}

const i18n = new VueI18n({
  locale: locale, // set locale
  messages: {
    en: en,
    ja: ja,
    tw: tw,
  },
})

export default i18n
